import React from 'react';
import PageTitle from '../PageTitle';
import Post from '../Post';
import WhatCanYouDo from '../WhatCanYouDo';

const FalseStatementPage = () => (
  <div className="page-component">
    <PageTitle text="False Statement Made By Planning Department" />
      <div className="page__div">
        On September 10, 2019, the  Santa Cruz County Planning Department
        made a  false statement
        to the public and to the Board Of Supervisors
        facilitating the adoption of the new General Plan amendments concerning allowable noise levels.
        <blockquote className="page__blockquote quote-font">
          "The amendments do not increase allowable noise levels."
        </blockquote>
        <cite><span className="bolder">David Carlson</span>, Planner, Santa Cruz County Planning Department</cite>
      </div>
      <div className="page__div">
        <h3 className="margin-top20">Policy 9.3.1 of the amendments sets the noise limits for events</h3> 
        <span className="medium-font margin-top10">from Policy 9.3.1: </span>
        <blockquote className="page__blockquote typewriter-font">
          Noise levels at the property line of any receiving
          land use shall conform to the standards of Table 9-2 and Table 9-3.
        </blockquote>
        <h3 className="margin-top20">Policy 9.3.2 allows all noise limits in Policy 9.3.1 to be overridden</h3>
        <span className="medium-font margin-top10">from Policy 9.3.2:</span>
        <blockquote className="page__blockquote typewriter-font">
          Discretionary Use Permits involving <span className="redText typewriter-font"> proposed noise levels that exceed the
          limitations of <span className="nowrap typewriter-font">Policy 9.3.1</span> shall only be considered ...</span>
        </blockquote>
        <blockquote className="page__blockquote typewriter-font">
          The limits of Policy 9.3.1 shall apply <span className="redText typewriter-font">unless different limits
          are set by conditions of approval</span> of the use <span className="nowrap typewriter-font">permit ...</span>
        </blockquote>
      </div>
      <div className="page__div">
        <h3>
          Prior to the new amendments, the General Plan Noise Element precluded overriding
          of noise level limits in <span className="nowrap">Policy 6.9.4:</span>
        </h3>
        <blockquote className="page__blockquote">
          <span className="typewriter-font redText">
            In no case shall the noise levels exceed the standards of
              <span className="nowrap typewriter-font"> Figure 6-2.</span>
          </span>
        </blockquote>
      </div>
      <WhatCanYouDo />
      <Post
        type="gj"
        month="Feb"
        year="2020"
        linkText="Request to the Santa Cruz County Grand Jury to investigate the Santa Cruz County Board of Supervisors over event noise irregularities"
        route="/grandJury"
      />
      <a className="page__anchor" href="https://www.sccoplanning.com/Portals/2/County/userfiles/106/GP_Chapter%209_Noise.pdf">
        Santa Cruz County General Plan Noise Element
      </a>
  </div>
);

export default FalseStatementPage;
