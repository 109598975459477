import React from 'react';
import { NavLink } from 'react-router-dom'; 
import PageTitle from '../PageTitle';
import Post from '../Post';
import WhatCanYouDo from '../WhatCanYouDo';

const EnvironmentalInjusticePage = () => (
  <div className="page-component">
    <PageTitle text="Supervisors Systematically Dismantled Residents' Noise Pollution Protections In Favor Of Business Interests" />
    <div className="page__div">
      <ul className="gj-ul">
        The Supervisors dismantled Residents' noise protections in four steps:
        <li className="gj-li">
          The Supervisors adopted a new Noise Ordinance setting a very high noise threshold of 75 decibels allowed to cross 
          residential property boundaries.  This facilitates parties and events at Vacation Rentals. 
          <NavLink to="/noiseOrdinance"  className="page__anchor" >see Noise Ordinance Post</NavLink>
          </li>
        <li className="gj-li">
          The Supervisors adopted a new General Plan Noise Chapter that allowed unlimited noise from events to cross 
          residential property boundaries.
          <NavLink to="/GPNoiseChapter" className="page__anchor" >see General Plan Noise Chapter Post</NavLink>
        </li>
        <li className="gj-li">
          The Supervisors attempted to adopt a new Noise Planning Chapter exempting commerical events from all noise standards.
          <NavLink to="/stealthChangeNC" className="page__anchor">see Stealth Change Made To Noise Planning Chapter Post</NavLink>
        </li>
        <li className="gj-li"> 
          The Supervisors are modifying the Home Occupation Ordinance noise standards to allow an increase in noise 
          from no noise allowed to cross property boundaries to potentially unlimited noise to cross residential property boundaries.
          <NavLink to="/HomeOccupationNoise" className="page__anchor">see Home Occupation Noise Change Post here</NavLink>
        </li>
      </ul>
    </div>
    <WhatCanYouDo />
</div>
);

export default EnvironmentalInjusticePage;