import React from 'react';
import PageTitle from '../PageTitle';
import Post from '../Post';

const EventNoisePage = () => (
  <div className="page-component">
    <PageTitle text="Parties, Weddings, Concerts, and Raves" />
    <div className="page__div">
      Our County Supervisors' agenda is to allow <span className="bolder"> unlimited events on residential parcels </span>as part of
      their Economic Vitality Plan
    </div>
    <Post
      type="event"
      month="Apr"
      year="2020"
      linkText="Stealth change made to new Noise Planning Chapter exempted commercial events from noise level limits"
      route="/stealthChangeNC"
    />
    <Post
      type="event"
      month="Feb"
      year="2020"
      linkText="False statement made by Planning Department to public and Board Of Supervisors facilitates approval of General Plan amendments allowing more noise on residential parcels"
      route="/falseStatement"
    />
    <Post
      type="event"
      month="Feb"
      year="2020"
      linkText="Stealth change made to the General Plan Amendments allows County to grant permits that override all noise level limits"
      route="/stealthChangeGP"
    />
    <Post
      type="gj"
      month="Feb"
      year="2020"
      linkText="Request to the Santa Cruz County Grand Jury to investigate the Santa Cruz County Board of Supervisors over event noise irregularities"
      route="/grandJury"
    />
  </div>
);

export default EventNoisePage;
