import React from 'react';
import PageTitle from '../PageTitle';
import Figure from '../Figure';

const FireCodePage = () => (
    <div className="page-component">
        <PageTitle text="Substandard Fire Code Adopted By Board Of Supervisors" />
        <Figure
        src="./images/fire/Fire.jpeg"
        alt="Fire in the Santa Cruz Mountains"
        attribution="Cannabis Related Fire"
        figcaption="Santa Cruz Mountains -- 2016"
        addTheseClasses={["image-container__img--width"]}
        />
    <div className="page__div">
        <p>
            The Board Of Supervisors adopted substandard fire apparatus road widths for parcels in the 
            State Responsibility Area (SRA).  Specifically, required road widths were reduced 
            from 20 feet to 12 feet for <span className="bolder">all driveways serving two or fewer 
            habitable structures and unlimited non-habitable structures</span>.  
        </p>
        <p>
            This modification does not adhere to the 
            California Fire Code 2019.  The California Fire Code states that Fire Apparatus Access 
            Roads are to be 20 feet wide and are needed within 150 feet of structures.  The California Fire Code 
            does allow some exceptions. 
            An exception can be made to the distance from the Fire Apparatus Access Road 
            to structures if there are less than 3 structures of certain types,
            such as dwellings.  For example, if there no more than two habitable structures, the distance 
            from the dwellings to the Fire Apparatus Access Road may be increased beyond 150 feet, as I understand it.
            This information can be found at the Buildings and Facilties link below.
        </p>
        <h3 className="fire-protection-page__h3">Fire Apparatus Access Roads Links:</h3>
        <a className="page__anchor" href="https://codes.iccsafe.org/content/IFC2018P4/chapter-5-fire-service-features#IFC2018P4_Pt03_Ch05_Sec503">
            International Fire Code 2018, Scroll to 503.2.1 Dimensions
        </a>
        <a className="page__anchor" href="https://codes.iccsafe.org/content/CFC2019P2/chapter-5-fire-service-features#CFC2019P2_Pt03_Ch05_Sec503">
        California Fire Code 2019, Title 19, Scroll to 503.1.1 Buildings and Facilities
        </a>
        <a className="page__anchor" href="https://codes.iccsafe.org/content/CFC2019P2/chapter-5-fire-service-features#CFC2019P2_Pt03_Ch05_Sec503">
        California Fire Code 2019, Title 19, Scroll to 503.2 Specifications
        </a>
    </div>
    <div className="page__div">
        The Board Of Supervisors adopted Appendix B of the International Fire Code on Dec 10, 2019 
        as part of the Santa Cruz County Fire Code.  Appendix B of the International Fire Code authorizes the Fire Code 
        Official to use the International Wildland Urban Interface Code (IWUIC) for determining the required water supply
         for parcels where there is no water purveyor.  The California Fire Code 2019 does not allow for the use of the 
         IWUIC.  The IWUIC exempts garages, carpots, sheds, or agrigultural buildings having a footprint 
         less than 600 square feet.or less from having an approved water supply. The author can find no such exemption in the California Fire Code 2019.
        <a className="page__anchor" href="https://santacruzcountyca.iqm2.com/Citizens/Detail_Meeting.aspx?ID=1737">
            Click here to get to BOS meeting 9/12/2019, then click on item 9.a.
        </a>
        The above link shows the document where the Supervisors adopted Appendix B from the International 
        Fire Code.  The image below is from this document.
        <h3 className="fire-protection-page__h3">Water Supply Links:</h3>
        <a className="page__anchor" href="https://codes.iccsafe.org/content/CFC2019P2/appendix-b-fire-flow-requirements-for-buildings">
            Appendix B California Fire Code 2019. Scroll to B 103.3
        </a>
        <a className="page__anchor"  href="https://codes.iccsafe.org/content/IFC2018P4/appendix-b-fire-flow-requirements-for-buildings">
            Appendix B ICC Fire Code 2018, Scroll to B 103.3
        </a>
        <a className="page__anchor" href="https://codes.iccsafe.org/content/IWUIC2018/chapter-4-wildland-urban-interface-area-requirements#IWUIC2018_Ch04_Sec404.1">
            Water Supply, WUIIC 2018, Scroll to 402.2.2 Water Supply Exceptions Paragraph 2.
        </a>
    </div>
    <Figure 
        src="./images/fire/SCCOFireCodeDoc.PNG"
        alt="Santa Cruz County Fire Code Document"
        attribution="Santa Cruz County Fire Code"
        figcaption="Appendix B of International Fire Code Adopted By Supervisors"
        addTheseClasses={["image-container__img--width"]}
    />
    <div className="page__div">
        <h3>Santa Cruz County Grand Jury Fire Report Link:</h3>
        <a className="page__anchor" href="https://www.co.santa-cruz.ca.us/Portals/0/County/GrandJury/GJ2020_final/FireRisks_Report.pdf">
            2019/2020 Santa Cruz County Grand Jury Report
        </a>
    </div>
    </div>
);

export default FireCodePage;