import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default class MenuDrawer extends React.Component {

  constructor(props) {
    super(props);
  }

  render() {
    const { drawerHandler, drawerIsOpen } = this.props;
    const subscription = localStorage.getItem('subscription');
    const tabindex = drawerIsOpen ? "0" : "-1";
    return (
        <div onClick={drawerHandler} className={drawerIsOpen ? 'menu-drawer menu-drawer--open' : 'menu-drawer'}>
          <button className="menu-drawer__button" tabIndex={tabindex}><FontAwesomeIcon className="menu-drawer__icon" icon="times"/></button>
          <NavLink exact to="/" className="menu-drawer__navlink" activeClassName="menu-drawer--is-active" tabIndex={tabindex}>Home</NavLink>
          <NavLink to="/about" className="menu-drawer__navlink" activeClassName="menu-drawer--is-active" tabIndex={tabindex}>About</NavLink>
          <NavLink to="/events" className="menu-drawer__navlink" activeClassName="menu-drawer--is-active" tabIndex={tabindex}>Event Noise Posts</NavLink>
          <NavLink to="/fire" className="menu-drawer__navlink" activeClassName="menu-drawer--is-active" tabIndex={tabindex}>Fire Posts</NavLink>
          <NavLink to="/environmentalInjustice" className="menu-drawer__navlink" activeClassName="menu-drawer--is-active" tabIndex={tabindex}>Environmental Injustice Posts</NavLink>
          <NavLink to="/nextGen" className="menu-drawer__navlink" activeClassName="menu-drawer--is-active" tabIndex={tabindex}>Plane Noise Posts</NavLink>
          <NavLink to="/grandJury" className="menu-drawer__navlink" activeClassName="menu-drawer--is-active" tabIndex={tabindex}>Grand Jury Investigation</NavLink>
          {/*!subscription &&
          <NavLink to="/subscribe" className="menu-drawer__navlink" activeClassName="menu-drawer--is-active" tabIndex={tabindex}>Subscribe</NavLink>*/}
          </div>
    );
  }
}
