import React from 'react';
import { BrowserRouter, Redirect, Route, Switch } from 'react-router-dom';
import Header from '../components/Header';
import Footer from '../components/Footer';
import HomePage from '../components/pages/HomePage';
import AboutPage from '../components/pages/AboutPage';
import EventNoisePage from '../components/pages/EventNoisePage';
import GrandJuryPage from '../components/pages/GrandJuryPage';
import PlanesPage from '../components/pages/PlanesPage';
import FireCodePage from '../components/pages/FireCodePage';
import FirePage from '../components/pages/FirePage';
import FireProtectionPage from '../components/pages/FireProtectionPage';
import SJCPacArrivalsPage from '../components/pages/SJCPacArrivalsPage';
import SJCBrixxArrivalsPage from '../components/pages/SJCBrixxArrivalsPage';
import ToBeDonePage from '../components/pages/ToBeDonePage';
import NotFoundPage from '../components/pages/NotFoundPage';
import GPNoiseFirstAttemptPage from '../components/pages/GPNoiseFirstAttemptPage';
import DisclaimerPage from '../components/pages/DisclaimerPage';
import LicensesPage from '../components/pages/LicensesPage';
import FalseStatementPage from '../components/pages/FalseStatementPage';
import StealthChangeGPPage from '../components/pages/StealthChangeGPPage';
import MapDistortionsPage from '../components/pages/MapDistortionsPage';
import NoiseOrdinancePage from '../components/pages/NoiseOrdinancePage';
import StealthChangeNCPage from '../components/pages/StealthChangeNCPage';
import LicensePage from '../components/pages/LicensePage';
import EnvironmentalInjusticePage from '../components/pages/EnvironmentalInjusticePage';
import GeneralPlanNoiseChapterPage from '../components/pages/GeneralPlanNoiseChapterPage';
import gpCeqaExemptionPage from '../components/pages/gpCeqaExemptionPage';
import HomeOccupationNoisePage from '../components/pages/HomeOccupationNoisePage';

/*import EmailSubscribePage from '../components/pages/EmailSubscribePage';
import SubscribeConfirmationPage from '../components/pages/SubscribeConfirmationPage';
import UnsubscribePage from '../components/pages/UnsubscribePage';
import UnsubscribeConfirmationPage from '../components/pages/UnsubscribeConfirmationPage';*/
import ScrollToTop from '../components/ScrollToTop';

const AppRouter = (props) => {
  //const subscription = localStorage.getItem('subscription');
  return (
  <BrowserRouter>
    <div className="app-router">
      <Header />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={HomePage} />
        <Route path="/about" component={AboutPage} />
        <Route path="/events" component={EventNoisePage} />
        <Route path="/fireCode" component={FireCodePage} />
        <Route path="/fireProtection" component={FireProtectionPage} />
        <Route path="/nextGen" component={PlanesPage} />
        <Route path="/grandJury" component={GrandJuryPage} />
        <Route path="/fire" component={FirePage} />
        <Route path="/GenPlanNoise1" component={GPNoiseFirstAttemptPage} />
        <Route path="/SJCPacificArrivals" component={SJCPacArrivalsPage} />
        <Route path="/SJCBrixxArrivals" component={SJCBrixxArrivalsPage} />
        <Route path="/departures" component={ToBeDonePage} />
        <Route path="/propPlanes" component={ToBeDonePage} />
        <Route path="/overflights" component={ToBeDonePage} />
        <Route path="/vectoring" component={ToBeDonePage} />
        <Route path="/disclaimer" component={DisclaimerPage} />
        <Route path="/licenses" component={LicensesPage} />
        <Route path="/falseStatement" component={FalseStatementPage} />
        <Route path="/stealthChangeGP" component={StealthChangeGPPage} />
        <Route path="/stealthChangeNC" component={StealthChangeNCPage} />
        <Route path="/mapDistortions" component={MapDistortionsPage} />
        <Route path="/noiseOrdinance" component={NoiseOrdinancePage} />
        <Route path="/GPNoiseChapter" component={GeneralPlanNoiseChapterPage} />
        <Route path="/environmentalInjustice" component={EnvironmentalInjusticePage} />
        <Route path="/gpCeqaExemption" component={gpCeqaExemptionPage} />
        <Route path="/HomeOccupationNoise" component={HomeOccupationNoisePage} />
        {/*<Route exact path="/subscribe" component={EmailSubscribePage} />
        <Route exact path="/subscribe/:id" component={SubscribeConfirmationPage} />
        <Route exact path="/unsubscribe/:id" component={UnsubscribePage} />
        <Route path='/license' component={LicensePage} />
        <Route path="/unsubscribeConfirmation/:id" component={UnsubscribeConfirmationPage} />*/}
        <Route path='/license' component={LicensePage} />
        <Route component={NotFoundPage} />
      </Switch>
      <Footer />
    </div>
  </BrowserRouter>
);}

export default AppRouter;
