import React from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Post = (props) => {
  const { type, month, year, linkText, route } = props;
  let icon, headerText;
  switch (type) {
    case "event" :
      icon = "bullhorn";
      headerText = "Event Noise Post"
      break;
    case "plane" :
      icon = "plane";
      headerText = "Plane Noise Post"
      break;
    case "gj" :
      icon = "gavel";
      headerText = "Grand Jury Post"
      break;
    case "fire" :
      icon = "fire-alt";
      headerText = "Fire Post"
      break;
    case "injustice" :
      icon = "balance-scale-right";
      headerText = "Injustice Post"
      break;
    default:
      icon = ""
      headerText=""
  }

  return (
    <div className="post">
      <div className="post__header">
        <span>
          <FontAwesomeIcon className="post__header__icon" icon={icon} />
        </span>
        <span className="post__header__type">
          {headerText}
        </span>
        <span className="post__header__date small-font">
          {`${month} ${year}`}
        </span>
      </div>
      <div className="post__navlink">
        <NavLink to={route}>{linkText}</NavLink>
      </div>
    </div>
  );
}

export default Post;
