import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';

const MenuBar = (props) => {
    const { closeDrawerHandler, drawerHandler } = props;
    return (
      <nav className="menubar">
        <div onClick={drawerHandler} className="menubar__navlink">
          <FontAwesomeIcon className="fa-2x" icon="bars" />
        </div>
        <NavLink 
            onClick={closeDrawerHandler} 
            className="menubar__navlink" activeClassName="menubar--is-active" to="/">
            <FontAwesomeIcon className="fa-2x" icon="home" />
        </NavLink>
        <NavLink onClick={closeDrawerHandler} className="menubar__navlink  menubar__navlink--padding" activeClassName="menubar--is-active" to="/events">Events</NavLink>
        <NavLink onClick={closeDrawerHandler} className="menubar__navlink  menubar__navlink--padding" activeClassName="menubar--is-active" to="/fire">Fire</NavLink>
        <NavLink onClick={closeDrawerHandler} className="menubar__navlink  menubar__navlink--padding" activeClassName="menubar--is-active" to="/environmentalInjustice">Injustice</NavLink>
        <NavLink onClick={closeDrawerHandler} className="menubar__navlink  menubar__navlink--padding" activeClassName="menubar--is-active" to="/nextGen">Planes</NavLink>
      </nav>
    );
  }

export default MenuBar;
