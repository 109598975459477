import React from 'react';
import { NavLink } from 'react-router-dom';
import EmailSupervisors from './EmailSupervisors';

const WhatCanYouDo = () => (
    <div className="page__div">
        <h3 className="gj-ul">
            What can you do?  
        </h3> 
            Contact the Santa Cruz County Grand Jury and ask them to investigate.
            <div className="margin-top20">The Grand Jury is required to:</div>
            <div>
                <blockquote className="page__blockquote typewriter-font">
                    Inquire as necessary into the willful or corrupt misconduct in office of public officers
                </blockquote>
                <a className="margin-top20" href="https://www.santacruzcourt.org/divisions/jury/grand%20jury">More about the Santa Cruz County Grand Jury can be found here</a>
            </div>
            <div className="margin-top20">
                A request to the Grand Jury to investigate can be made via email, via the USPS, or via hand delivery.
                You'll need to fill out the <a href="https://www.co.santa-cruz.ca.us/Portals/0/County/GrandJury/gjComplaintFormEnglish.pdf">
                Santa Cruz County Grand Jury Form found here
                </a>
            </div>
            <a className="page__anchor" href="https://www.co.santa-cruz.ca.us/Departments/GrandJury.aspx">contact info for the Santa Cruz County Grand Jury can be found here</a>
            <p className="margin-top20">Feel free to reference the <NavLink to="/grandJury">Blue Skies request to the Santa Cruz County Grand Jury to investigate. </NavLink>
            </p>
            <div className="margin-top20">Your request could include the following sentence:
                <blockquote className="page__blockquote typewriter-font">
                    Please investigate the irregularities concerning changes to Residents' noise protections 
                    identified in the Blue Skies Grand Jury Request that can be found at this web address: 
                    <div className="small-font margin-top10">www.blueskies4scco.com/grandJury</div>
                </blockquote>
            </div>
    </div>
)

export default WhatCanYouDo;