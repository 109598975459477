import React from 'react';
import PageTitle from '../PageTitle';
import Post from '../Post';
import WhatCanYouDo from '../WhatCanYouDo';

const StealthChangeGPPage = () => (
  <div className="page-component">
    <PageTitle text="Stealth Change Made To Amendments Allows Overriding Of All Noise Limits" />
    <div className="page__div">
      <span className="bolder">An unannounced change was made to Policy 9.3.1 </span> of
      the General Plan Amendments between
      August 27, 2019 and September 10, 2019 allowing the county to override
      all noise level limits for periodic events on residential parcels.
    </div>
    <div className="page__div">
      The addition of Table 9-3 to Policy 9.3.1 in conjunction with
      Policy 9.3.2 <span className="bolder">allows the County to
      grant Permits exceeding all
      noise level limits for periodic events on residential parcels.</span>
    </div>
    <div className="page__div">
      <span className="medium-font">Policy 9.3.1 </span>-- August 27
      <blockquote className="page__blockquote typewriter-font">
        Noise levels at the property line of any receiving
        land use shall conform to the standards of
        <span className="nowrap typewriter-font"> Table 9-2. </span>
      </blockquote>
      <span className="medium-font">Policy 9.3.1 </span>-- September 10
      <blockquote className="page__blockquote typewriter-font">
        Noise levels at the property line of any receiving
        land use shall conform to the standards of Table 9-2
        <span className="nowrap redText typewriter-font bolder medium-font"> and Table 9-3.</span>
      </blockquote>
      <span className="medium-font">Policy 9.3.2 </span>
      <blockquote className="page__blockquote typewriter-font">
        <span className="redText typewriter-font"> Discretionary Use Permits involving
        proposed
        noise levels that exceed
        the limitations of Policy 9.3.1 </span>shall only be considered for approval
        through a public hearing process before either the Zoning Administrator
        or Planning <span className="nowrap"> Commission ...</span>
      </blockquote>
    </div>
    <div className="page__div">
      <span className="bolder">
        Prior to the change, Policy 9.2.4 precluded unlimited noise levels.
      </span>
      <blockquote className="page__blockquote typewriter-font">
        In no case shall the noise levels exceed the standards of Table 9-3
      </blockquote>
    </div>
    <div className="page__div">
      The <span className="bolder"> public was not duly informed </span>of this change.
    </div>
    <div className="page__div">
      The intent to change Policy 9.3.1 to include Table 9-3 <span className="bolder"> was not
      conveyed to the public </span> verbally on August 27, 2019 by the
      Supervisors.
    </div>
    <div className="page__div">
      The actual change to Policy 9.3.1 that included Table 9-3
      <span className="bolder"> was not
      conveyed to the public </span> verbally at the public hearing on
      September 10, 2019.
    </div>
    <div className="page__div">
      The actual change to Policy 9.3.1
      <span className="bolder"> was not clearly marked in any
      document.</span>
    </div>
    <WhatCanYouDo />
    <Post
      type="gj"
      month="Feb"
      year="2020"
      linkText="Request to the Santa Cruz County Grand Jury to investigate the Santa Cruz County Board of Supervisors over event noise irregularities"
      route="/grandJury"
    />
    <a className="page__anchor" href="https://www.sccoplanning.com/Portals/2/County/userfiles/106/GP_Chapter%209_Noise.pdf">
      Santa Cruz County General Plan Noise Element
    </a>
  </div>
);

export default StealthChangeGPPage;
