import React from 'react';
import PageTitle from '../PageTitle';
import WhatCanYouDo from '../WhatCanYouDo';

const gpCeqaExemptionPage = () => (
  <div className="page-component">
    <PageTitle text="The CEQA Notice Of Exemption Issued For The General Plan Noise Chapter Was Inappropriate" />
    <div className="page__div">
        The CEQA Notice Of Exemption was issued with the following justification:
      <blockquote className="page__blockquote typewriter-font">
        The proposed amendments are exempt from CEQA pursuant to 14 C.C.R. section 15061(b)(3) because they
        implement existing law (the State Airport Land Use Planning Handbook and existing local noise 
        standards), <span className="redText typewriter-font bolder">
        they can be found to be exempt under the “Common Sense” exemption where it can be seen with certainty 
        that there is
        no possibility that the activity in question may have a significant effect on the environment</span>,
        and pursuant to 14 C.C.R
        section 15308 because they are actions taken by regulatory agencies, as authorized by state or local ordinance, to
        assure the maintenance, restoration, enhancement, or protection of the environment where the regulatory process
        involves procedures for protection of the environment.
      </blockquote>
    </div>
    <div className="page__div">
          <ul className="gj-ul">
            <span className="bolder">
              In direct contradiction to the CEQA Notice Of Exemption</span>,
              the new General Plan Noise Chapter 
              details that the increase in noise levels will be impactful to residents. 
            <li className="gj-li">
              <div className="bolder">From the Level Of Sound Paragraph in the new General Plan Noise Chapter:</div>
              <blockquote className="page__blockquote typewriter-font">
                A 10 dB change is subjectively heard as approximately a doubling in loudness and would almost
                certainly cause an adverse community response.
              </blockquote>
              <div className="margin-top20">
                The new General Plan Noise Chapter references the new Offensive Noise Chapter 6 times. 
                The Offensive Noise Chapter <span className="bolder">sets noise limits 30 decibels higher</span> than what our current
                General Plan Noise Element allows. This is almost <span className="bolder">8 times louder</span> than what our current 
                General Plan Noise Element allows.
              </div>
            </li>
            <li className="gj-li">
            <div className="bolder">Objective 9-3 from the new General Plan Noise Chapter:</div>
                <blockquote className="page__blockquote typewriter-font">
                  Noise Generated by Operations of Wineries, Quarries and Other Special Uses
                  or Periodic Activities. <span className="redText typewriter-font">Recognize special conditions and establish specialized maximum noise standards
                  for certain uses and activities that can occur in rural and agricultural areas with potential to affect noise
                  sensitive areas or receptors</span>; such as for noise levels associated with winery operations, quarries and mining
                  activities, and other special uses, temporary uses, periodic activities, or other similar situations as identified
                  by County Code or land use permitting regulations.
                </blockquote>
            </li>
            <li className="gj-li">
              <div className="bolder">Policy 9.3.2 from the new General Plan Noise Chapter</div>
              <blockquote className="page__blockquote typewriter-font"><span className="redText typewriter-font"> Discretionary 
              Use Permits involving proposed noise levels that exceed the
                limitations of Policy 9.3.1 </span> shall only be considered for approval through a public
                hearing process before either the Zoning Administrator or Planning Commission...
              </blockquote>
              <blockquote className="page__blockquote typewriter-font">
                The limits of Policy 9.3.1 shall apply <span className="redText typewriter-font">unless 
                different limits
                are set by conditions of approval of the use permit </span>
                based upon the individual merits
                of the location and surroundings of the winery and proposed operations.
              </blockquote>
            </li>
            <li className="gj-li">
              <div className="bolder">Policy 9.3.4 from the new General Plan Noise Chapter</div>
              <blockquote className="page__blockquote typewriter-font">
                The decision-maker or decision-making body taking action to approve a Special
                Event Permit, amplified sound permit, or other permit that authorizes special or
                periodic uses or activities that will generate noise that is not typical for the context
                of the proposed location, <span className="typewriter-font redText"><span className="bolder">
                may</span> impose conditions of 
                approval that limit the maximum noise level...</span>
              </blockquote> 
            </li>
          </ul>
        </div>
        <WhatCanYouDo />
    </div>
);

export default gpCeqaExemptionPage;