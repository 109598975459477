import React from 'react';
import { NavLink } from 'react-router-dom';
import PageTitle from '../PageTitle';
import Post from '../Post';
import WhatCanYouDo from '../WhatCanYouDo';

const GeneralPlanNoiseChapterPage = () => (
    <div className="page-component">
        <PageTitle text="General Plan Noise Chapter Irregularites" />
        <div className="page__div">
            On September 10, 2019 the Supervisors adopted a new General Plan Noise Chapter.  Multiple
            irregularites, if not illegalities occurred.
        </div>
        <div className="page__div">
            <ul className="gj-ul">
                <li className="gj-li">
                    David Carlson made a false statement at the Public Hearing in response to concerns voiced by 
                    Residents. The false statement mislead the Public.
                    <NavLink to="/falseStatement"  className="page__anchor" activeClassName="menu-drawer--is-active">see more about the False Statement here</NavLink>
                </li>
                <li className="gj-li">
                    An unannounced undocumented change was made to the General Plan Noise Chapter allowing the County 
                    to override all noise level standards.
                    <NavLink to="/stealthChangeGP"  className="page__anchor" activeClassName="menu-drawer--is-active">see more about the stealth change here</NavLink>
                </li>
                <li className="gj-li">
                    A CEQA Notice Of Exemption was inappropriately issued by the County for the new General Plan Noise Chapter.
                    <NavLink to="/gpCEQAexemption"  className="page__anchor" activeClassName="menu-drawer--is-active">see more about the inappropriate CEQA Exemption here</NavLink>
                </li>
            </ul>
        </div>
        <WhatCanYouDo />
    </div>
);

export default GeneralPlanNoiseChapterPage;
