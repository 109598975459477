import React from 'react';

const Figure = (props) => {
  const { src, alt, attribution, figcaption, addTheseClasses=[], link } = props;
  const classes = ["image-container__img", ...addTheseClasses];
  return (
    <div>
      <figure>
        <figcaption>{figcaption}</figcaption>
        <div className="image-container">
          <img className={classes.join(" ")} src={src} alt={alt} />
        </div>
        <div className="figure__attribution">
          {attribution}
          {link && <a className="page__anchor" href={link.link}>{link.text}</a>}
        </div>
      </figure>
    </div>
  );
};

export default Figure;
