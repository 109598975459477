import React from 'react';
import PageTitle from '../PageTitle';
import Figure from '../Figure';

const MapDistortionsPage = () => (
  <div className="page-component">
    <PageTitle text="FAA map distortions" />
    <div className="page__div">
      The FAA maps used to convey the NextGen route changes to the general public are
      distorted. <span className="bolder"> Why would the FAA use maps and routes with unusual distortions
      to inform the public of the route changes?</span>
    </div>
    <Figure
      src="./images/planes/EastWestFAA.PNG"
      alt="NextGen map"
      attribution="FAA map"
      figcaption="Why is Ano Nuevo State Park (reference point on left) above Anderson Lake
      (reference point on right) in the FAA map?"
    />
    <Figure
      src="./images/planes/EastWestGoogle.PNG"
      alt="google map"
      attribution="google map"
      figcaption="Ano Nuevo State Park (reference point on left) is below Anderson Lake (reference point on right) in google maps."
    />
    <div className="page__div">
      The FAA map uses a map projection called Lambert Conformal Conic.
      This projection causes
      significant distortion at our location. This distortion makes it difficult for the general
      public to read the maps and understand where the routes are located.  Why didn't the
      FAA use google maps?
    </div>
    <div className="page__div">
      The FAA map depicts Ano Nuevo
      State Park NORTH west of Anderson Lake.  Google maps depicts Ano Nuevo State Park
      SOUTH west of Anderson Lake. The distortion can be seen in the map images above.  Note the
      difference in the angle of the line drawn on the FAA map vs the angle of the line drawn on the google map.
      The lines are drawn between the same two points on both maps.
    </div>
  {/*  <div className="page__div">
      The NextGen EA maps lack details such as Hwy 85, Hwy 17, Hwy 9, and Hwy 35.
      Why did the FAA omit these Highways from the maps?
    </div>
    <Figure
      src="./images/NoLandmarks.png"
      alt="NextGen map"
      attribution="from the FAA NextGen Environmental Assessment for the NorCal Metroplex "
      figcaption="Omission of Hwys 85, 17, 9, and 35 made it difficult for the
      general public to understand route locations."
    />
    <div className="page__div">
      The draft NextGen EA maps depict existing and new routes, but
      the final NextGen EA maps omit the lines depicting the routes.
      Why did the FAA
      remove the route depictions from the maps in the final NextGen EA?
      Removal of the route locations from the final NextGen Environmental Assessment maps makes
      it difficult to determine where the routes were, where the routes will be, and what the
      changes were to the routes.
    </div>
    <div className="page__div">
      The NextGen EA maps did not depict the flight tracks for the
      SJC Pacific arrivals for the West Flow no action alternative and action alternatives.
      Curiously, the SJC Pacific arrivals WERE depicted in the maps for the East flow action alternative and
      the east flow no action alternative.  Why were the SJC Pacific Arrivals omitted from the
      NextGen EA diagrams?  omission of the SJC Pacific Arrivals made it difficult to understand
      if changes were planned to the flight tracks and who would be impacted by those changes.
    </div>
      <Link className="page__link" to="/SJCPacificArrivals">SJC Pacific Arrivals</Link> */}
    <a className="page__anchor" href="http://www.metroplexenvironmental.com/docs/norcal_metroplex/NorCal_OAPM_FEA_Complete.pdf">
      NorCal FAA Environmental Assessment <span className="nowrap"> Final Complete</span>
    </a>
    <a className="page__anchor" href="http://www.metroplexenvironmental.com/docs/norcal_metroplex/NorCal_OAPM_DEA_Complete.pdf">
      NorCal FAA Environmental Assessment<span className="nowrap"> Final Draft</span>
    </a>
    <a className="page__anchor" href="http://www.metroplexenvironmental.com/norcal_metroplex/norcal_docs.html">
      All docs for NorCal NextGen
    </a>
  </div>
);

export default MapDistortionsPage;
