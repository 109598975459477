import React from 'react';
import PageTitle from '../PageTitle';
import Figure from '../Figure';

const FireProtectionPage = () => (
    <div className="page-component">
        <PageTitle text="Supervisors' Failure To Mitigate Fire Risk Contributes To Increasing Insurance Non-renewals And Endangers Residents" />
        <Figure
        src="./images/fire/FireProtectionOrg.PNG"
        alt="Org Chart For Fire Protection"
        attribution="from the Santa Cruz County Grand Jury Report 2019/2020"
        figcaption="Expensive Fire Protection Patchwork Lacks Effective Leadership"
        addTheseClasses={["image-container__img--width"]}
        />
    <div className="page__div">
        <h3>CZU Lightening Complex Fire reveals that our fire protection patchwork lacks effective leadership</h3>
        <p>Why are our Supervisors at the top of the organization chart shown above?</p>
        <a className="bolder redText" href="https://www.latimes.com/california/story/2020-08-24/fire-santa-cruz-mountains-firefighters-fema">
            Los Angeles Times Story - Abandoned by authorities, these fire-threatened towns set up their own FEMA-like response
        </a>
    </div>
    <div className="page__div">
        <h3 className="fire-protection-page__h3 ">The Santa Cruz County Grand Jury investigated our fire protection efforts</h3>
        Some of the findings are shown below.
        <a className="page__anchor" href="https://www.co.santa-cruz.ca.us/Portals/0/County/GrandJury/GJ2020_final/FireRisks_Report.pdf">See the entire Grand Jury Report on Fire Risks</a>
    </div>
    <div className="page__div">
        Grand Jury Finding 1, Fire Risk Mitigation: 
        <h3 className="fire-protection-page__h3 ">County Fails To Manage Roadside Vegetation</h3>
        <blockquote className="page__blockquote typewriter-font">
            Vegetation/fuel management and abatement are not receiving the attention nor
            funding needed from the County of Santa Cruz Board of Supervisors, and
            therefore <span className="redText bolder typewriter-font">are not adhering to California Government Executive Order
            1.8.19-EO-N-05-19.</span>
        </blockquote>
        <h3 className="fire-protection-page__h3 " className="redText">On Jun 6, 2020, the Board Of Supervisors Canceled Funding For Roadside Vegetation Management</h3>
        <a className="page__anchor" href="https://santacruzcountyca.iqm2.com/Citizens/Detail_Meeting.aspx?ID=1802">See Item 69, Board Of Supervisors Meeting Agenda</a>
    </div>
    <div className="page__div">
        Grand Jury Finding 2, Fire Risk Mitigation: 
        <h3 className="fire-protection-page__h3 ">County Fails To Identify, Track, and Assess Fire Risks</h3>
        <blockquote className="page__blockquote typewriter-font">
        Santa Cruz County residents are at increased risk of fire danger due to the lack
        of risk management for wildfire. Specific risks are not formally identified, tracked,
        assessed for impact, nor is progress reported by fire departments in the County.
        </blockquote>
    </div>
    <div className="page__div">
        Grand Jury Finding 3, Fire Risk Mitigation: 
        <h3 className="fire-protection-page__h3 ">County Fails To Collaborate With PG&E To Identify Fire Risks</h3>
        <blockquote className="page__blockquote typewriter-font">
            City and County officials have not collaborated with PG&E to identify the location
            of high risk PG&E electrical equipment, and so are left uninformed as to how to
            manage their responsibilities or how to instruct residents about potential danger
            due to proximity to this equipment.
        </blockquote>
    </div>
    <div className="page__div">
        Grand Jury Finding 4, Fire Risk Mitigation:
        <h3 className="fire-protection-page__h3 ">County Fails To Deploy Imaging Surveillance Systems</h3>
        <blockquote className="page__blockquote typewriter-font">
            Most of Santa Cruz County in addition to the City of Santa Cruz with its large
            eucalyptus groves are not being monitored by the ALERTWildfire Imaging
            Surveillance system and would be well served by the installation of cameras
            capable of monitoring coastal areas occupied by eucalyptus groves in areas
            harboring potential sources of ignition.
        </blockquote>
    </div>
    <div className="page__div">
        Grand Jury Finding 5, Fire Risk Mitigation: 
        <h3 className="fire-protection-page__h3 ">County Fails To Develop Site Specific Community Wildfire Protection Plans</h3>
        <blockquote className="page__blockquote typewriter-font">
            Santa Cruz County would greatly benefit if steps were taken to implement the
            CAL FIRE, San Mateo - Santa Cruz Unit 2018 recommendation of developing
            detailed, site specific Community Wildfire Protection Plans for communities
            throughout the County.
        </blockquote>
    </div>
    <div className="page__div">
        Grand Jury Finding 12, Alerts And Evacuations:  
        <h3 className="fire-protection-page__h3 ">County Fails To Deploy Warning Devices</h3>
        <blockquote className="page__blockquote typewriter-font">
            Long Range Acoustic Devices (LRADs), have been deployed in other areas of
            the state and have proven effective tools in alerting residents in urban and rural
            areas to a wildfire. However, Santa Cruz County has no such devices, increasing
            the risk to County residents
        </blockquote>
    </div>
    <div className="page__div">
        Grand Jury Finding 13, Alerts and Evacuations:
        <h3 className="fire-protection-page__h3 ">County Fails To Publish Refuge/Assembly Area Information</h3>
        <blockquote className="page__blockquote typewriter-font">
            F13. High risk communities in the County are left unnecessarily vulnerable due to the
            lack of easily accessible, published information of refuge/assembly areas and
            structures.
        </blockquote>
    </div>
    <div className="page__div">
        Grand Jury Finding 15, Alerts and Evacuations:
        <h3 className="fire-protection-page__h3 ">County Fails To Publish Emergency Evacuation Routes</h3>
        <blockquote className="page__blockquote typewriter-font">
            Unlike the City of Santa Cruz, the County does not publish emergency
            evacuation routes, purportedly to avoid having old or untimely information being
            followed in an emergency. <span className="typewriter-font redText">The County therefore 
            withholds revealing evacuation
            routes until an emergency is in progress, likely creating unnecessary risk and
            potential for chaos.</span>
        </blockquote>
    </div>
</div>
);

export default FireProtectionPage;
