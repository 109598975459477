import React from 'react';
import { NavLink } from 'react-router-dom';
import PageTitle from '../PageTitle';
import Post from '../Post';
import WhatCanYouDo from '../WhatCanYouDo';

const NoiseOrdinancePage = () => (
  <div className="page-component">
    <PageTitle text="Supervisors Adopted A New Noise Ordinance Facilitating Vacation Rental Party Houses" />
        <div className="page__div">
            In order to facilitate parties and other events at vacation rentals and other venues, 
            the <span className="bolder">Supervisors adopted a new Noise Ordinance 
            that allows almost an 8-fold increase
            in the noise </span>from commercial activities allowed to cross property boundaries.
        </div>
        <div className="page__div">
            <span className="bolder">
            The Supervisors falsely presented the Noise Ordinance to the public 
            as a means for Law Enforcement Officers to address noise
            complaints stemming from parties and events on residential parcels. </span> 
            The 75 decibel threshold set in the Noise 
            Ordinance is a clear indicator that the intention 
            was not to limit noise.
        </div>
        <div className="page__div"><span className="bolder">
        The General Plan, in place at the time of the Noise Ordinance adoption,
            specified a maximum hourly average noise limit of 45 to 50 decibels,
            depending on the ambient noise level.  It also stated that in no case 
            were these noise level limits to be exceeded.
        </span>
        </div>
        <div className="page__div">
            <span className="bolder">The Supervisors ignored the General Plan </span> and adopted the new Offensive Noise 
            Ordinance with 
            <span className="bolder"> no Environmental Impact Report </span> as was required by the California 
            Environmental Quality Act.
        </div>
        <div className="page__div">
            <p>
                An hourly average noise limit of 70 - 73 decibels
                is now allowed.  The change from an hourly average noise 
                level from 45 dB to 75 dB is an 8-fold increase.
            </p>
            <h3>
            A change from 45 dB to 55 db is 2 times louder.
        </h3>
        <h3>A change from 45 dB to 65 db is 4 times louder</h3>
        <h3>A change from 45 dB to 75 dB is 8 times louder</h3>
        </div>
        <WhatCanYouDo />
        <Post
            type="gj"
            month="Feb"
            year="2020"
            linkText="Request to the Santa Cruz County Grand Jury to investigate the Santa Cruz County Board of Supervisors over event noise irregularities"
            route="/grandJury"
        />
    </div>
)

export default NoiseOrdinancePage;