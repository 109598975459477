import React from 'react';
import PageTitle from '../PageTitle';
import Figure from '../Figure';
import Post from '../Post';

const StealthChangeNCPage = () => (
  <div className="page-component">
    <PageTitle text="Stealth Change Made To New Noise Planning Chapter Exempted Commercial Events From Noise Standards" />
    <div className="page__div">
      An unannounced change was made to the new Noise Planning Chapter Exemptions Paragraph
      <span className="bolder"> exempting commerical events from the noise standards.</span>
    </div>
    <div className="page__div">
      On September 10, the new Noise Planning Chapter was
      reviewed and approved for adoption by the Board of Supervisors during
      a public hearing.  The new Noise Planning Chapter
      contained revisions to the Exemptions Paragraph as
      shown below.
    </div>
    <Figure
      src="./images/Approved.PNG"
      alt="approved exemptions paragraph"
      attribution="Revised Exemptions Paragraph, Noise Panning Chapter approved on Sept 10 to be adopted on Sept 24"
      figcaption="Revised Exemptions Paragraph - Approved"
    />
    <div className="page__div">
      <a href="./noisePlanningChapterSept10.pdf" download>Clicking this link will download the Noise Planning Chapter approved for adoption on September 10</a>
    </div>
    <div className="page__div"><span className="bolder">
      On September 24, the new Noise Planning Chapter listed for final
      adoption contained the unrevised Exemptions Paragraph exempting
      commerical events from the noise standards.
    </span></div>
    <div className="page__div">
      An unannounced change was made to the Exemptions Paragraph between September 10 and September 24
      restoring the unrevised Exemptions Paragraph.
    </div>
    <div className="page__div">
      The unrevised Exemptions Paragraph
      exempted shows, bands, sporting, and entertainment events between the hours of
      8 a.m. and 10:00 p.m. from the noise standards.
    </div>
    <Figure
      src="./images/NotApproved.PNG"
      alt="unannounced change to exemptions paragraph"
      attribution="Unrevised Exemptions Paragraph, Noise Planning Chapter, listed for final adoption on Sept 24"
      figcaption="Unrevised Exemptions Paragraph - Not Approved"
    />
    <div className="page__div">
      <a href="./noisePlanningChapterSept24.pdf" download>Clicking this link will download the Noise Planning Chapter listed for final adoption on September 24</a>
    </div>
    <div className="page__div">
      A local resident caught the unannounced change and notified the Board Of Supervisors.  The revised approved Exemptions Paragraph was then
      restored to the new Noise Planning Chapter.
    </div>
    <div className="page__div"><span className="bolder">
      The link to the new Noise Planning Chapter listed for final adoption on September 24 has since been removed from the
      County's website.</span>  However, it is available for download at the link above.
    </div>
    <div className="page__div">
      The new Noise Planning Chapter listed for final adoption on September 24
      (that can be downloaded above) was
      created on September 12, 2019.  This verifies the <span className="bolder"> document
      was created after the
      Board Of Supervisors approval of
      the new Noise Planning Chapter on September 10.</span>
    </div>
    <div className="page__div"><span className="bolder">
      The signature is illegible and not dated </span>
      on the new Noise Planning Chapter listed for final adoption on September 24
      (that can be downloaded above) .
    </div>
    <Post
      type="gj"
      month="Feb"
      year="2020"
      linkText="Request to the Santa Cruz County Grand Jury to investigate the Santa Cruz County Board of Supervisors over event noise irregularities"
      route="/grandJury"
    />
  </div>
);

export default StealthChangeNCPage;
