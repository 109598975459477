import React from 'react';
import PageTitle from '../PageTitle';
import { NavLink } from 'react-router-dom';

const LicensesPage = () => (
  <div className="page-component">
    <PageTitle text="Licenses" />
    <div className="page__div">
    <NavLink className="page__anchor" to="/license">blueskies4scco.com</NavLink>
    <a href="https://github.com/expressjs/express/blob/HEAD/LICENSE" className="page__anchor">express</a>
    <a href="https://fontawesome.com/v4.7.0/license/" className="page__anchor">fontawesome</a>
    {/*<a href="https://github.com/helmetjs/helmet/blob/master/LICENSE/" className="page__anchor">helmet</a>
    <a href="https://github.com/auth0/node-jsonwebtoken/blob/master/LICENSE/" className="page__anchor">jsonwebtoken</a>
    <a href="https://github.com/moment/moment/blob/develop/LICENSE" className="page__anchor">moment</a>
<a href="https://github.com/mysql/mysql-connector-nodejs/blob/master/LICENSE" className="page__anchor">mysql/xdevapi</a> */}
    <a href="https://github.com/nodejs/node/blob/master/LICENSE" className="page__anchor">node</a>
   {/*} <a href="https://nodemailer.com/about/license/" className="page__anchor">nodemailer</a>*/}
    <a href="https://github.com/facebook/react/blob/master/LICENSE"  className="page__anchor">react</a>
    <a href="https://github.com/FortAwesome/react-fontawesome/blob/master/LICENSE.txt" className="page__anchor">react-fontawesome</a>
    <a href="https://github.com/ReactTraining/react-router/raw/master/LICENSE" className="page__anchor">react-router</a>
   {/* <a href="https://github.com/uuidjs/uuid/blob/master/LICENSE.md" className="page__anchor">uuid</a>
<a href="https://github.com/validatorjs/validator.js/blob/master/LICENSE" className="page__anchor">validator</a>*/}
    </div>
  </div>
);

export default LicensesPage;
