import React from 'react';
import PageTitle from '../PageTitle';
import ImageContainer from '../ImageContainer';

const GPNoiseFirstAttemptPage = () => (
  <div className="page-component">
    <PageTitle text="County Government attempts to double the acceptable air traffic noise over rural residents" />
    <div className="page__div">
      On February 25, 2015 the Santa Cruz County Planning Department
      presented a new General Plan Noise Chapter to the Santa Cruz County
      Planning Commission for approval with <span className="bolder"> math signs of all factors reversed</span>.  The new Noise Chapter was
      unanimously approved by the Planning Commission.
    </div>
    <figure>
      <figcaption>Santa Cruz County reverses all math signs for EPA Normalization Factors
      </figcaption>
      <ImageContainer src="./images/NormalizationCounty.png" alt="Santa Cruz County Noise Normalization Factors with reversed math signs" />
    </figure>
    <figure>
      <figcaption>California General Plan Guidelines with correct EPA Normalization Factors
      </figcaption>
      <ImageContainer src="./images/CaliforniaNoiseNorm.PNG" alt="Noise Normalization Factors from the California General Plan Guidelines" />
    </figure>
    <div className="page__div">
      Blue Skies believes this was an attempt by the Supervisors to create
        <span className="bolder"> two
          different classes of residents with vastly different air traffic noise
          protections.
        </span>
    </div>
    <div className="page__div">
      The new General Plan Noise Chapter
      <span className="bolder"> doubled the acceptable
        air traffic noise level for rural residents. </span>
        (See explanation below.)
    </div>
    <div className="page__div">
    The new General Plan Noise Chapter
      <span className="bolder"> halved the acceptable
      air traffic noise level for urban residents. </span>
      (See explanation below.)
    </div>
    <div className="page__div">
      The new General Plan Noise Chapter
      <span className="bolder"> misused
        adjustments created by the EPA. </span>
        The EPA created these adjustments to recognize the impactfulness of
        air traffic noise on quiet rural residential areas.  Basically, air traffic noise is
        far more impactful in quieter areas.
    </div>
    <div className="page__div"><span className="bolder">
      This misuse of the EPA adjustments known as
      normalization factors is unprecedented.
      </span>
    </div>
    <div className="page__div">Blue Skies interprets this is
      <span className="bolder"> an invitation by the
        Santa Cruz County Supervisors to the FAA to concentrate
        air traffic over quiet rural residential areas </span>
        of Santa Cruz County.
    </div>
    <div className="page__div">
      The authors of the new General Plan Noise Chapter, the
      Santa Cruz County Planning Department,
      <span className="bolder"> recognized the
        impactfulness of the air traffic noise to quiet rural areas
         and misused the adjustments.
      </span>
    </div>
    <div className="page__div">
      The Santa Cruz County Planning Department follows the instructions of the
      County Board of Supervisors.
    </div>
    <div className="page__div">
      The Santa Cruz County Planning Commission follows the instructions of the
      County Board of Supervisors as the Commissioners are appointed by the Supervisors.
    </div>
    <div className="page__div">
      So what happened to the new General Plan Noise Chapter?  The Board of
      Supervisors was made aware that rural residents were aware of the misuse of
      the EPA adjustments.  The new General Plan Noise chapter was then quietly
      shelved.
    </div>
    <div className="page__div">Explanation of EPA Normalization Factors:
      EPA Normalization Factors are used to adjust the measured CNEL noise level to
      determine if the noise level is acceptable.
    </div>
    <div className="page__div">
      Using the factors correctly for quiet rural areas adds 10 decibels to the measured CNEL in decibels
      thereby adjusting the measured CNEL to twice what was measured.  This reduces the
      acceptable noise level.
    </div>
    <div className="page__div">
      Using the factors incorrectly for quiet rural areas, as our County did,
      subtracts 10 decibels from the measured CNEL in decibels thereby
      adjusting the measured CNEL to half of what was measured.  This increases the
      acceptable noise level.
    </div>
    <a
      className="page__anchor"
      href="https://sccounty01.co.santa-cruz.ca.us/planning/plnmeetings/PLNSupMaterial/PC/agendas/2015/20150225/007.pdf"
    >
      New General Plan Noise Chapter <span className="nowrap">Feb 2015</span>
    </a>
  </div>
);

export default GPNoiseFirstAttemptPage;
