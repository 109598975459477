import React from 'react';
import Post from '../Post';
import PageTitle from '../PageTitle';
import Figure from '../Figure';

const SJCBrixxArrivalsPage = () => (
  <div className="page-component">
    <PageTitle text="SJC Northwest Arrival Route Shifted And Concentrated Towards The Summit Area" />
    <div className="page__div">
      The existing arrival route called GOLDN was
      <span className="bolder"> shifted west towards the Summit area </span>and renamed BRIXX.
    </div>
    <div className="page__div">
      The second leg of the existing arrival route called GOLDN was eliminated
      <span className="bolder"> concentrating arrivals near and over the Summit area.</span>
    </div>
    <Figure
      src="./images/planes/GOLDN&BRIXX.png"
      alt="SJC arrival route changes from FAA EA Draft map"
      attribution="FAA EA Draft Map"
      figcaption="The FAA shifted noise and emissions away from Monte Sereno, Los Gatos, Almaden, and San Jose towards the Summit area"
    />
    <div className="page__div">
      <span className="bolder">
        Shifting the route west made a longer less fuel efficient route </span>
        - one of the primary
        goals of NextGen was to reduce fuel consumption and emissions.
    </div>
    <div className="page__div">
      <span className="bolder">
        Eliminating the 2nd leg of the route made a longer less fuel efficient route </span> - one of the
        primary goals of NextGen was to reduce fuel consumption and emissions.
    </div>
    <div className="page__div">
      The<span className="bolder"> FAA representative
      misrepresented the route changes to the public
      indicating it was basically unchanged </span>
      during the Select Committee hearings.
    </div>
    <div className="page__div">
      <span className="bolder">
        The Select Committee members were notified of the misrepresentation.  </span>
        Neither the Select Committee Members nor the FAA corrected the inaccurate information presented to the public
        during the Select Committee hearings.
    </div>
    <div className="page__div">
      All Select Committee members, including
      <span className="bolder"> Supervisor Leopold and Supervisor McPherson,
        voted in favor of routes being located over less populated areas.</span>  Blue Skies believes
        this endorsed the shifting and concentrating of the SJC Northwestern arrivals away from the
        more densely populated cities towards the Summit area.
    </div>
    <Post
      type="plane"
      month="Feb"
      year="2020"
      linkText="Distortions in FAA NextGen Environmental Assessment maps obfuscate route locations and route changes"
      route="/mapDistortions"
    />
      <a className="page__anchor" href="http://www.metroplexenvironmental.com/docs/norcal_metroplex/NorCal_OAPM_DEA_Complete.pdf">NorCal FAA Environmental Assessment Draft</a>
      <a className="page__anchor" href="http://www.metroplexenvironmental.com/norcal_metroplex/norcal_docs.html">All docs for NorCal NextGen</a>
  </div>
);

export default SJCBrixxArrivalsPage;
