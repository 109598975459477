import React from 'react';

class EmailSupervisors extends React.Component {
  constructor(props) {
    super(props);
    this.supervisors =  [
      {
        name: "John Leopold" ,
        id: 0,
        district: 1,
        email: "john.leopold@santacruzcounty.us",
      },
      {
        name: "Zach Friend" ,
        id: 1,
        district: 2,
        email: "zach.friend@santacruzcounty.us",
      },
      {
        name: "Ryan Coonerty" ,
        id: 2,
        district: 3,
        email: "ryan.coonerty@santacruzcounty.us",
      },
      {
        name: "Greg Caput" ,
        id: 3,
        district: 4,
        email: "greg.caput@santacruzcounty.us",
      },
      {
        name: "Bruce McPherson" ,
        id: 4,
        district: 5,
        email: "bruce.mcpherson@santacruzcounty.us",
      },
    ];

    this.state = {
      checked: [false, false, false, false, false],
    }
  }

  toggleSupsCheckbox = (e) => {
    const toggleTarget = parseInt(e.target.id, 10);
    this.setState((prevState) => ({
      checked: this.state.checked.map((boolValue, index) =>
        index === toggleTarget
        ? !boolValue
        : boolValue
    )}));
  };

  getRecipients = () => {
    const reducer = (acc, sup) => {
      if (this.state.checked[sup.id]) {
        acc.push(sup.email)
      }
      return acc;
    }
    const str = this.supervisors.reduce(reducer, []).join(';');
    console.log(str);
    return str;
  }

  render() {
    const recipients = this.getRecipients();
     return (
      <div className="page-component">
        <div className="bolder">Step 1: Select email recipients</div>
        <ul>
          {this.supervisors.map(sup => (
            <li key={`supervisor${sup.district}`} className="email-page__li">
              <input
                className="email-page__li__checkbox"
                type="checkbox"
                id={sup.id}
                name={`sup${sup.district}`}
                checked={this.state.checked[sup.id]}
                onChange={this.toggleSupsCheckbox}
              />
              <label className="email-page__li__label">
                {`Supervisor ${sup.name}`},<br />
                {`District ${sup.district}`}
              </label>
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

export default EmailSupervisors;
