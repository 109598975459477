import React from 'react';
import PageTitle from '../PageTitle';


function GrandJuryPage() {
  return (
    <section className="page-component">
      <PageTitle text="A Request To The Santa Cruz County Grand Jury To Investigate The Following:" />
      <ul className="gj-ul">
        <li className="gj-li"><h4 className="gj-h4">Did The
          Supervisors, Zoning Administrator, Planning Commission, Or Planning Department Conspire To
          Defraud Residents Of A CEQA Review and EIR For The New General Plan Noise Chapter,
          The New Noise Planning Chapter, And/Or The Offensive Noise Chapter <strong>To Facilitate Having Event Venues And Other Noise Generating Businesses On Residential Parcels?</strong>
        </h4></li>
        <li className="gj-li">
          <h4 className="gj-h4">
            Did The Supervisors, Zoning Administrator,
            Planning Commission,
            Or Planning Department Conspire To Set Precendents By Issuing Permits Exceeding The Noise Limits
            Of The Current General Plan and County Code <strong>To Facilitate Having Event
            Venues And Other Noise Generating Businesses On Residential Parcels?</strong>
          </h4>
        </li>
      </ul>
    <ul>
      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 1:</h5>
        <div className="gj-p">
        <p>The County issued a Notice Of Exemption for the new General Plan Noise Element
          although multiple paragraphs in the new General Plan Noise Element indicate
          it can be seen with certainty
          that increases in noise will have a significant impact to Residents.
          CEQA exemptions apply only to projects which do not have the potential
          to cause significant impacts to the environment. Therefore
          <span className="redText"> a CEQA Review and EIR are required.</span>
          </p>
          <p>
          The text of 14 C.C.R. section 15061(b)(3), shown below, indicates
          <span className="redText"> a
          CEQA Review and EIR are required. </span>
        </p>
        <blockquote className="gj-blockquote">
            (3) The activity is covered by the common sense exemption that CEQA
            applies only to projects which have the potential for causing a
            significant effect on the environment. <span className="redText">Where it can be seen with
            certainty that there is no possibility that the activity in question
            may have a significant effect on the environment, </span>
            the activity is not subject to CEQA.
          </blockquote>
        </div>
          <div className="gj-p">
            <p>The Level Of Sound Paragraph in the new General Plan Noise Chapter
            indicates a 10 decibel change is
              impactful.  Therefore
              <span className="redText"> a CEQA Review and EIR are required.</span>
            </p>
            <blockquote className="gj-blockquote">
              "A <span className="redText"> 10 dB </span>
              change is subjectively heard as approximately a doubling in loudness and would almost
                certainly cause an adverse community response."
            </blockquote>
            <p>Policy 9.3.2 of the new General Plan Noise Chapter
              allows discretionary permits to be issued for any noise level
            </p>
            <p>The new General Plan Noise Chapter references the Offensive Noise Chapter
              6 times.  The Offensive Noise Chapter sets noise limits at
              <span className="redText"> 30 decibels </span>
              higher than
              what our current General Plan Noise Element allows.  This is almost 8 times
              louder than what our current General Plan Noise Element allows.
            </p>
          </div>
          <div className="gj-p">
            <p>The Amplified Sound Paragraph of the new General Plan Noise Chapter indicates
              amplified noise is impactful to residents.  Therefore,
              <span className="redText"> a CEQA Review and EIR are required.</span></p>
            <blockquote className="gj-blockquote">
              Amplified sound includes noise from personal or home audio equipment,
              automotive audio equipment, outdoor loudspeakers, such as those used
              for paging, and amplified sound at music or theatrical performances.
              <span className="redText"> Because this sound typically includes music or speech, it is potentially
                more detectable and
                annoying to some people and sensitive receptors </span>
              than other sounds of
              the same noise level.
            </blockquote>
          </div>
          <div className="gj-p">
            <p>Objective 9-3 of the new General Plan Noise Chapter recognizes special
              conditions that allow noise levels that are impactful to residents.
              Therefore, <span className="redText"> a CEQA Review and EIR are required.</span>
            </p>
            <blockquote className="gj-blockquote">
              Recognize special conditions and establish specialized maximum noise
              standards for certain uses and activities that can occur in rural
              and agricultural areas with potential to affect noise sensitive
              areas or receptors
            </blockquote>
          </div>
          <div className="gj-p">
            <p>Policy 9.3.2 allows noise limits specified in the new General Plan
              Noise Chapter to be exceeded with a Discretionary Permit to
              unspecified levels.  Therefore,
              <span className="redText"> a CEQA Review and EIR are required.</span></p>
            <blockquote className="gj-blockquote">
              "Discretionary Use Permits involving proposed noise levels that
              exceed the limitations of Policy 9.3.1 shall only be considered for
              approval through a public hearing process before either the Zoning
              Administrator or Planning Commission,"
            </blockquote>
          </div>
      </li>
      <li>
        <h5 id="9-28:1" className="gj-h5">Possible Irregularity And/Or Impropriety 2:</h5>
        <div className="gj-p">
          <p>
            The County issues a Notice Of Exemption for the new General Plan Noise Chapter and
            the new SCCC Chapter 13.15 Noise Planning citing
          </p>
            <blockquote className="gj-blockquote">
              "The proposed amendments are exempt from CEQA pursuant to 14
              C.C.R. section 15061(b)(3) because they implement existing law
              (existing local noise standards)"
            </blockquote>
            <p>
              This is incorrect as it refers to the Offensive Noise Chapter
              which is not used to set stationary noise limits.
            </p>
            <p>
              The current noise standard for stationary noise sources
              used for commercial purposes on
              residential parcels is set by the Home Occupation Noise Standard, County Code 13.10.613 (B) (9).
            </p>
            <blockquote className="gj-blockquote">
              <strong>County Code 13.10.613 (B) (9) All
                noise shall be contained within the boundaries of the site.</strong>
            </blockquote>
            <p>
              For nonresidential parcels
              it is set by Paragraph 6.9.4 Figure
              6-2 Maximum Allowable Stationary Noise Sources of the General Plan Noise Element.
              This paragraph also states:
            </p>
          <blockquote className="gj-blockquote">
            <span className="redText">"In no case shall the noise levels exceed the standards of Figure 6-2."</span>
          </blockquote>
        </div>
        <div className="gj-p">
          <p>
            The Planning Department does not currently use the Offensive Noise Chapter to set noise limits for stationary noise sources.
          </p>
        </div>
        <div className="gj-p">
          <p>A letter form Edward L. Pack and Associates to Lori Johnson clearly states that the
          Offensive Noise Chapter is not for stationary noise sources such as music at events,
          but is for offensive noise.
          </p>
          <blockquote className="gj-blockquote">
            "This letter has been prepared to address music sound levels in relation to the
            provisions of the Santa Cruz County Noise Chapter 4001 (1989) Chapter 8.30 and
            the newly adopted Chapter 5205 (2016) Chapter 8.30. <span className="redText"> Neither versions of the
            Chapter mandate sound level limits on a sound source, rather they define Offensive Noise
            and place limits/standards on sound sources that may be determined to be offensive."</span>
          </blockquote>
          <div className="image-div">
            <img className="details-image" src="./images/PackLetter.PNG" alt="Letter From Edward L. Pack and Associates" />
          </div>
        </div>
      </li>
      <li>
        <h5 id="9-28:2" className="gj-h5">Possible Irregularity And/Or Impropriety 3:</h5>
        <div className="gj-p">
          <p>The Planning Department representative falsely stated on 9/10 at the BOS Meeting</p>
          <blockquote className="gj-blockquote redText">
            "The Amendments do not increase allowable noise levels"
          </blockquote>
        </div>
        <div className="gj-p">
          <p>Policy 9.3.2 of the new General Plan Noise Chapter allows unlimited noise</p>
          <blockquote className="gj-blockquote redText">
            "The limits of Policy 9.3.1 shall apply unless different limits are set
            by conditions of approval of the use permit"
          </blockquote>
          <blockquote className="gj-blockquote redText">
            "Discretionary Use Permits involving proposed noise levels <span className="redText">that
            exceed the limitations
            of Policy 9.3.1 </span>..."
          </blockquote>
        </div>
        <div className="gj-p">
          <p>
              The current noise standard for stationary noise sources used for
              commercial purposes on residential
              parcels is set by the Home Occupation Noise Standard, County Code 13.10.613 (B) (9).
          </p>
          <blockquote className="gj-blockquote">
            <strong>
                County Code 13.10.613 (B) (9) All
                noise shall be contained within the boundaries of the site.
            </strong>
          </blockquote>
          <p>
            For nonresidential parcels
            it is set by Paragraph 6.9.4 Figure
            6-2 Maximum Allowable Stationary Noise Sources of the General Plan Noise Element.
          </p>
        </div>
      </li>
      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 4:</h5>

        <div className="gj-p">
          <p>
            A change was made to Policy 9.3.1 of
            the new General Plan Noise Chapter (GPNC)
            between 8/27 and 9/10 allowing unlimited noise to cross residential property
            boundaries. <span className="redText">The addition of Table 9-3 to
            Policy 9.3.1 in conjunction with Policy 9.3.2 allows the Planning Department to override any and all
            noise limits specified in the new GPNC.</span>
          </p>
          <p>Policy 9.3.1</p>
          <blockquote className="gj-blockquote">
          "Noise levels at the property line of any receiving land use shall conform to the standards of Table 9-2"</blockquote>
          <blockquote className="gj-blockquote">
            "Noise levels at the property line of any receiving land use shall conform
            to the standards of Table 9-2<span className="redText"> and Table 9-3"</span>
          </blockquote>
          <p>Policy 9.3.2</p>
          <blockquote className="gj-blockquote">
            "Discretionary Use Permits involving proposed <span className="redText"> noise levels that exceed
             the limitations of Policy 9.3.1 </span>shall only be considered for approval
             through a public hearing process before either the Zoning Administrator or Planning Commission ..."
          </blockquote>
        </div>
        <div className="gj-p">
          <p>
          The public was not duly informed.
          </p>
          <p>The Planning Department representative falsely stated on 9/10 at the BOS Meeting</p>
          <blockquote className="gj-blockquote redText">
            "The Amendments do not increase allowable noise levels"
          </blockquote>
        </div>
        <div className="gj-p">
          <p>Prior to the change, Policy 9.2.4 precluded unlimited noise levels.</p>
          <blockquote className="gj-blockquote">
            <span className="redText">"In no case shall the noise levels exceed the standards of Table 9-3"</span>
          </blockquote>
        </div>
        <div className="gj-p">
          <p>
            Policy 9.3.2 clearly indicates the intention to override all noise limits.
          </p>
          <blockquote className="gj-blockquote redText">
            "Discretionary Use Permits involving proposed noise levels <span className="redText">that
            exceed the limitations
            of Policy 9.3.1 </span>shall only be considered for approval through a public hearing process before
            either the Zoning Administrator or Planning Commission ..."
          </blockquote>
          <blockquote className="gj-blockquote redText">
            "The limits of Policy 9.3.1 shall apply unless different limits are set
            by conditions of approval of the use permit"
          </blockquote>
        </div>
        <p className="gj-p">
          The intent to change Policy 9.3.1 to include
             Table 9-3 was not
          conveyed to the public verbally on 8/27 by the Supervisors.
        </p>
        <p className="gj-p">
          The actual change to Policy 9.3.1
          that included Table 9-3
          was not conveyed to the public verbally at the public hearing on 9/10.
        </p>
        <p className="gj-p">The actual change to Policy 9.3.1 was not clearly marked in any document.</p>
      </li>
      <li>
        <h5 id="9-30:1" className="gj-h5">Possible Irregularity And/Or Impropriety 5:</h5>
        <div className="gj-p">
          <p>
            The County issues a Notice Of Exemption for the new General Plan Noise Chapter and
            the new SCCC Chapter 13.15 Noise Planning <span className="redText">ignoring the recent court case
            involving a Santa Cruz Mountains Summit Area neighborhood group, Keep Our Mountains Quiet,
            and a local property owner who was hosting weddings.
            </span>
          </p>
          <p>
            The local property owner obtained a Permit
            from Santa Clara County to host weddings.  The neighbors pursued legal action.  <strong>The trial
            court and the court of appeal found a Mitigated Negative Declaration did not sufficiently
            address the environmental impacts the Permit might cause.</strong>
          </p>
          <p>If a Mitigated Negative Declaration did not sufficiently address the environmental issues
          for one permit, it seems unlikely that the CEQA Exemption declared by the County for the
          new General Plan Noise Chapter and the new Noise Planning Chapter
          would address the environmental
          impacts (noise and traffic) created by the changes made in the new General Plan Noise Chapter and the new
          Noise Planning Chapter impacting the entire County.
          </p>
          <p><strong>The court awarded the neighborhood group, Keep Our Mountains Quiet, attorney's fees.
          </strong></p>
          <p>
            <a href="https://www.law.com/therecorder/almID/1202725952060/?slreturn=20190830111157">
              Santa Cruz Mountains Summit Area Wedding Hosting Permit Case</a>
          </p>
          <p>
            <a href="https://appellatecases.courtinfo.ca.gov/search/case/partiesAndAttorneys.cfm?dist=6&doc_id=2047401&doc_no=H039707&request_token=NiIwLSIkTkg%2FWzBBSCItUExIUFw7UCxbICBOWzpSQCAgCg%3D%3D">
              Here are the Parties and Attorneys listed by the California Courts
            </a>
          </p>
          <p>
            <strong><a href="http://wittwerparkin.com/">William Parkin successfully represented Keep Our Mountains Quiet</a></strong>
          </p>
        </div>
      </li>
      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 6:</h5>
        <div className="gj-p">
          <p>
            No CEQA Review or EIR was done for the new Noise Planning Chapter
          </p>
          <p>A Project is subject to CEQA if</p>
            <blockquote className="gj-blockquote">
              "Activity has potential for a direct physical change or a
              reasonably foreseeable indirect physical change in the environment, and"
            </blockquote>
            <blockquote className="gj-blockquote">
              "Activities that are supported (in whole or in part) by public agency,
              which include contracts, grants, subsidies, loans, or other assistance
              from a public agency."
            </blockquote>
            <blockquote className="gj-blockquote">
              "Discretionary approval require the exercise of judgement or deliberation."
            </blockquote>
            <blockquote className="gj-blockquote">
              "An activity involving the public agency issuance of a lease, permit, license,
              certificate or other entitlement for use by a public agency."
            </blockquote>
          </div>
          <div className="gj-p">
            <p>Excerpts from the new Noise Planning Chapter satisfying the requirements for a CEQA Review and EIR</p>
            <blockquote  className="gj-blockquote">
            (C) Entertainment or Special Events. The provisions of this Chapter
             shall not apply to those reasonable sounds emanating from authorized
            school bands, school athletic and school entertainment events and
            occasional public and private outdoor or indoor gatherings, community
             events, public dances, shows, bands, sporting and entertainment events
              conducted between the hours of 8:00 a.m. and 10:00 p.m. or the applicable
              permitted timeframe, as long as any applicable requirements for
              <span className="redText"> special event permits or temporary
               use permits are met.</span>
            </blockquote>
            <blockquote className="gj-blockquote">
            (3) As a condition of approval for all <span className="redText">discretionary applications
              for new development</span> in other areas where noise exposures are known
              to, or are determined to, exceed the standards in Table 9-2 and 9-3
              of the General Plan Noise Element. Acoustical studies may be required
              to determine existing exterior noise levels and the level of sound
              insulation required.
            </blockquote>
            <blockquote className="gj-blockquote redText"><strong>
             (B) The standards in this section shall not limit the ability of
              the County to impose conditions of approval on projects
              that increase noise levels at existing
              noise-sensitive receptors or areas by any amount.
            </strong></blockquote>
          </div>
      </li>
      <li>
        <h5 id="9-22:1" className="gj-h5">Possible Irregularity And/Or Impropriety 7:</h5>
        <div className="gj-p">
          <p>
            The new Noise Planning Chapter approved for adoption on September 10 is
            not the same as the new Noise Planning Chapter listed on the Agenda for
            September 24 under Item 18 a for final adoption.
          </p>
        </div>
        <div className="gj-p">
          <p>13.15.040 Exemptions (C) Entertainment and Special Events,
          presented to the public and approved by the Supervisors on September 10
          for final adoption on September 24</p>
          <div className="image-div">
            <img className="details-image" src="./images/Approved.PNG" alt="Event Chapter noise standards document" />
          </div>
        </div>
        <div className="gj-p">
            <p>13.15.040 Exemptions (C) Entertainment and Special Events,
            listed for final adoption September 24, under Agenda Item 18</p>
            <p>Exempts shows, bands, sporting, and entertainment events beteen the hours of 8a.m. and 10:00p.m.</p>
            <div className="image-div">
              <img className="details-image" src="./images/NotApproved.PNG" alt="Event Chapter noise standards document" />
            </div>
        </div>
        <div className="gj-p">
            <p className="redText"><strong>The unapproved change
            exempts shows, bands, sporting, and entertainment events beteen the hours of 8 a.m. and 10:00 p.m. from
            the noise standards.
            </strong></p>
        </div>
      </li>
      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 8:</h5>
        <div className="gj-p">
          <p>
            The Supervisors Adopt an Offensive Noise Chapter that is not in agreement with
            our current General Plan Noise Element.  The Offensive Noise Chapter is also
            not in agreement with the Home Occupation Noise Standard.
          </p>
          <p>
            Paragraph 6.9.4 Commercial and Industrial Development of the
            current General Plan Noise Element specifies
            that Figure 6-2
            sets the noise limits for stationary noise sources
            The limits set are
            an Hourly Leq of either 45 or 50 decibels.
            It also states that
          </p>
          <blockquote className="gj-blockquote">
            "In no case shall the noise levels exceed the standards of Figure 6-2."
          </blockquote>
          <p className="redText">
            The Offensive Noise Chapter allows noise up to 75 decibels.  An hourly Leq of
            70 - 73 decibels would be under this 75 decibel threshhold.
          </p>
          <h5>45 decibels to 55 decibels - the noise sounds 2 times as loud</h5>
          <h5>45 decibels to 65 decibels - the noise sounds 4 times as loud</h5>
          <h5>45 decibels to 75 decibels - the noise sounds 8 times as loud</h5>
        </div>
      </li>
      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 9:</h5>
        <div className="gj-p">
          <p>On June 9, 2015, the Offensive Noise Chapter is presented to the public during
            the public hearing
            as a means to address the thousands of noise complaints each year.
          </p>
          <p>Many
            of these complaints come from people in rural areas where neighbors are
            holding events.
          </p>
          <p className="redText">
            According to one constituent in attendance, who testified at the hearing,
            he was contacted by Supervisor Friend prior to the hearing.
            Supervisor Friend requested the
            constituent testify at the hearing in favor of the Noise Chapter as
            the constiuent had called Supervisor Friend requesting relief from neighbors
            holding events on residential parcels.
          </p>
          <p>
            It is unclear how adopting an Offensive Noise Chapter that allows a noise
            level almost 8 times louder than what the General Plan Noise Element specifies
            would address residents' needs concerning noise generated from
            events on residential parcels.
          </p>
        </div>
      </li>
      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 10:</h5>
        <div className="gj-p">
          <p>
            The Offensive Noise Chapter is mentioned 6 times in the new General Plan Noise Chapter as
            a standard for noise.
          </p>
        </div>
        <div className="gj-p">
          <p>
            No CEQA Review or EIR was done for the Offensive Noise Chapter.  A CEQA Review and EIR are required
            if a project meets the following criteria
          </p>
          <p>A Project is subject to CEQA if</p>
            <blockquote className="gj-blockquote">
              "Activity has potential for a direct physical change or a
              reasonably foreseeable indirect physical change in the environment, and"
            </blockquote>
            <blockquote className="gj-blockquote">
              "Activities that are supported (in whole or in part) by public agency,
              which include contracts, grants, subsidies, loans, or other assistance
              from a public agency."
            </blockquote>
            <blockquote className="gj-blockquote">
              "Discretionary approval require the exercise of judgement or deliberation."
            </blockquote>
            <blockquote className="gj-blockquote">
              "An activity involving the public agency issuance of a lease, permit, license,
              certificate or other entitlement for use by a public agency."
            </blockquote>
          </div>
      </li>

      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 11:</h5>
        <div className="gj-p">
          <p>
          On June 9, 2015, the Planning Department presented a draft amendment to
          the Santa Cruz County Code Chapter 13.10 pertaining to commercial
          weddings and special events and community events and fundraisers that
          references the previous Offensive Noise Chapter with
          <span className="redText"> no noise limits from 8am until 10pm </span>
          as the noise standard for events.
          </p>
          <div className="image-div">
              <img className="details-image" src="./images/EventsStandards.PNG" alt="Event Chapter noise standards document" />
          </div>
          <p className="redText"><strong>It is unclear how adopting a noise standard of no noise limits
            would address residents' needs concerning noise generated from
            events on residential parcels.
          </strong></p>
        </div>
      </li>
      <li>
        <h5 id="9-24:1" className="gj-h5">Possible Irregularity And/Or Impropriety 12:</h5>
        <div className="gj-p">
          <p>
            On April 21, 2017, a permit for Application 151101 was issued for
            a Winery on a residential
             parcel allowing
            amplified music and weddings <strong>prior to the Environmental Review for and
             the adoption of the
            Code Modernization Amendments.  </strong>
            On May 24, 2017 an appeal was filed.  Inaccurate and misleading
            statements were made in the Planning Department
            Staff Report response to the Appeal.
          </p>
        </div>
        <div className="gj-p">
          <p>
          The Appellant asserted that special events associated with the proposed winery are not
          permitted in the RA Zone District because
            <span className="redText"> the proposed event use regulations have not yet
            been adopted by the Board Of Supervisors.
            </span>
          </p>
        </div>
        <div className="gj-p">
          <p>The Planning Department Staff responded with</p>
          <blockquote className="gj-blockquote redText">
            "While the set of Code Modernization amendments <strong>still awaits completion of
            environmental review</strong>, valuable guidance can be obtained from the draft regulations as
            staff and decision-makers evaluate applications involving wineries and residential home
            occupations in the interim before the amendments are adopted."
          </blockquote>
          <div className="image-div">
            <img
              src="./images/Appeal1.PNG" alt="Text from Staff Report"
              className="details-image"
            />
          </div>
        </div>
        <div className="gj-p">
          <p>And also responded with the following misleading statement</p>
          <blockquote className="gj-blockquote redText">
            "Examples of wineries that have authorized events include, but are not limited to,
            Regale and Silver Mountain Winery ..."
          </blockquote>
          <p className="redText">
              <strong>Silver Mountain Winery is on residential parcels. Regale is not. </strong>
              Silver Mountain Winery is <strong>NOT</strong> permitted to have any events outdoors other than on the
              Vintner's Festival (2 times per year) and the Passport Days (4 times per year).
              Silver Mountain Winery is <strong>NOT</strong> allowed to have weddings.  All events are required
              to be wine tasting events.  Silver Mountain Winery
              is <strong>NOT</strong> allowed to have amplified music.  Silver Mountain Winery is <strong>NOT </strong>
              allowed to have public address systems.
          </p>
          <div className="image-div">
            <img
              src="./images/Appeal2.PNG" alt="Text from staff Report"
              className="details-image"
            />
          </div>
        </div>
      </li>
      <li>
        <h5 id="2-26:1" className="gj-h5">Possible Irregularity And/Or Impropriety 13:</h5>
        <div className="gj-p">
          <p>
            Blue Skies believes the wrong Noise Standard was used for the Permit issued for Application 07-0507.  The
            Winery is located on residential parcels.  The Permit allows outdoor events with music
            for 6 events per year.  Blue Skies believes that any occupation conducted on residential parcels
            is subject to the Home Occupation Noise Standard, County Code 13.10.613 (B) (9).
          </p>
          <p>
          <span className="redText">The Winery Permit did not
          adhere to County Code 13.10.613 (B) (9)
          <strong> All
            noise shall be contained within the boundaries of the site.
          </strong></span>
        </p>
        </div>
      </li>
      <li>
        <h5 id="9-29:1" className="gj-h5">Possible Irregularity And/Or Impropriety 14:</h5>
        <div className="gj-p">
          <p>
            Blue Skies believes the Planning Department incorrectly determined the Winery Permit
            for Application 07-0507 was exempt from CEQA citing Class 1 Existing
            Facilities (Section 15301).  A Class 1 exemption
            requires negligible or no expansion of existing facilities.
          </p>
          <p>
            The Winery Permit allowed a substantial increase in use as public
            wine tastings were granted every Saturday, outdoor events with music were
            granted 6 days per year, and the size of the Winery
            was increased by converting an interior space previously used for the
            residence to part
            of the Winery.  Previously, no outdoor activites were permitted as
            the Winery is located on residential parcels.
          </p>
          <blockquote className="gj-blockquote">
          Class 1 consists of the operation, repair, maintenance, permitting,
          leasing, licensing, or minor
          alteration of existing public or private structures,
            facilities, mechanical equipment, or topographical
            features, involving negligible or no expansion of use
            beyond that existing at the time of the lead
            agency’s determination.
          </blockquote>
        </div>
      </li>
      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 15:</h5>
        <div className="gj-p">
          <p>The Castle House Permit for Application 131127, was issued.
          The Permit allowed outdoor weddings to be hosted
          on a residential parcel as a Home Occupation.</p>
          <p>
          The wrong noise standard was used for the Permit. The use of the wrong
          noise standard set a new precendent to facilitate using residential parcels
          as event venues for
          weddings. <span className="redText"> A court
          case ensued.  The Judge found the wrong Noise Standard had been used for the
          Permit that was issued.</span>
          </p>
          <p>
            <a href="https://sccounty01.co.santa-cruz.ca.us/planning/plnmeetings/PLNSupMaterial/Zoning/agendas/2014/20140905/001.pdf">
              Castle House Permit
            </a>
          </p>
        </div>
        <div className="gj-p">
          <span className="redText">The Castle House Permit approved by the Zoning Administrator did not
          adhere to County Code 13.10.613 (B) (9)
           <strong> All
          noise shall be contained within the boundaries of the site.
          </strong></span>
        </div>
        <div className="gj-p">
          <p>The Planning Department issued a CEQA Environmental Review  for the
          Castle House Permit that incorrectly
          marked Land Use Planning as not conflicting with any existing
          regulations It did conflict with County Code 13.10.613 (B) (9).
          </p>
          <div className="image-div">
            <img
              src="./images/CastleHouse.PNG" alt="Castle House Environmental Report"
              className="details-image"
            />
          </div>
        </div>
      </li>
      <li>
        <h5 className="gj-h5">Possible Irregularity And/Or Impropriety 16:</h5>
        <div className="gj-p">
          <p>The EPA introduced Noise Normalization Factors to take into account
          the increased impactfulness of noise on quieter areas.  These factors are
          added to the Measured CNEL or DNL in land use planning.
          </p>
          <p>The Planning Department presented a new General Plan Noise Chapter
           to the Planning Commission on February 25, 2015 that
           applied the Normalization Factors For Calculating Noise Exposure defined by
           the EPA incorrectly.
          </p>
          <p>
            The Planning Commission approved the new General Plan Noise Chapter that
            applied the Normalization Factors For Calculating Noise Exposure defined by
            the EPA incorrectly.
          </p>
          <p className="redText">
            The arithmatic signs of all Normalization Factors in Table 9-3  of
            the Planning Commission approved new General Plan Noise Element were reversed
            <strong> allowing a doubling and quadrupling of noise threshholds in quiet rural areas</strong>
          </p>
          </div>
          <div className="gj-p">
            <p>
              The table from Appendix D of the California General Plan Noise Guidelines showing
              the correct EPA Normalization Factors
            </p>
            <div className="image-div">
              <img className="details-image" src="./images/CaliforniaNoiseNorm.PNG"
                alt="Noise Normalization Factors from the California General Plan Guidelines" />
            </div>
          </div>
          <div className="gj-p">
            <p>
            The table from the Planning Commission approved new General Plan Noise Chapter showing
            all EPA Normalization Factors reversed.
            </p>
            <div className="image-div">
              <img className="details-image" src="./images/CountyNoiseNorm.PNG"
                alt="Noise Normalization Factors from the approved General Plan Noise Chapter" />
            </div>
          </div>
      </li>
    </ul>
    <h5 className="gj-h5">
      The County has not provided direct links to these documents.
      Here are indirect links.  Follow the links and then click on item 8a
      to see the actual documents
    </h5>
    <div className="gj-div">
      <a href="https://santacruzcountyca.iqm2.com/Citizens/Detail_Meeting.aspx?ID=1730">
        BOS 8/27 Meeting
        Follow this link and click on item 8a Resolution 197-2019 CEQA,
        GP/LCP Amendments, New Zoning District
      </a>
    </div>
    <div className="gj-div">
      <a href="https://santacruzcountyca.iqm2.com/Citizens/Detail_Meeting.aspx?ID=1731">
        BOS 9/10 Meeting
        Follow this link and click on item 8a Resolution 197-2019 CEQA,
        GP/LCP Amendments, New Zoning District
      </a>
    </div>
    </section>
  );
}

export default GrandJuryPage;
