import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="footer">
    <Link to="/disclaimer" className="footer__link small-font">disclaimer</Link>
    <Link to="/" className="footer__link small-font">www.blueskies4scco.com</Link>
    <Link to="/licenses" className="footer__link small-font">licenses</Link>
  </footer>
);

export default Footer;
