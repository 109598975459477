import React from 'react';
import PageTitle from '../PageTitle';
import Post from '../Post';

const PlanesPage = () => (
  <div className="page-component">
    <PageTitle text="Jets and Prop Planes" />
    <div className="page__div">
      Like other communities across the country, we have
      experienced a dramatic increase in plane noise since the implementation of NextGen.
    </div>
    <Post
      type="plane"
      month="Mar"
      year="2020"
      linkText="FAA shifted and concentrated the SJC northwest arrivals towards the Summit Area
       and away from the cities of Monte Sereno, Los Gatos, Almaden, and San Jose "
      route="/SJCBrixxArrivals"
    />
    <Post
      type="plane"
      month="Feb"
      year="2020"
      linkText="Distortions in FAA NextGen Environmental Assessment maps obfuscate route locations and route changes"
      route="/mapDistortions"
    />
    <Post
      type="plane"
      month="Feb"
      year="2020"
      linkText="Perverted use of EPA Noise Normalization factors by County attempts to double acceptable air traffic noise over rural residents"
      route="/GenPlanNoise1"
    />
    <div className="page__div">
      <span className="bolder">
        Communities in other parts of the country successfully
        addressed the NextGen
        flight path changes with legal action.
      </span>
    </div>
    <div className="page__div">The <span className="bolder"> Supervisors declined to take legal action </span>
      to
      address NextGen noise issues despite multiple irregularites.
      Instead, they promoted addressing the issues via dicussions with the FAA.</div>
    <div className="page__div">To date, <span className="bolder"> no flight paths have been restored to pre-NextGen locations </span>
    despite extensive discussions.</div>
  {/*  <div className="page__div">
      Communities in other parts of the country have had flight paths restored
      to pre-NextGen locations.
    </div>
    <div className="page__div">The Select Committee was formed to discuss the NextGen changes.
      Participation in the Select Committee may have compromised any future legal action
      to force restoration of flight paths to
      their pre-NextGen locations.</div>
    <div className="page__div">Santa Cruz County Select Committee members,
      Supervisors Leopold and McPherson, both
      voted that planes should fly over less populated areas thereby seemingly endorsing
      the route shifting that took place during NextGen from more heavily
      populated Santa Clara County communities towards Santa Cruz County communities.
    </div>
    <Link to="/SJCPacificArrivals">NextGen Irregularities concerning the SJC Pacific Arrivals</Link>
    <Link to="/SJCBrixxArrivals">NextGen Irregularities concerning the SJC BRIXX Arrivals</Link>*/}
  </div>
);

export default PlanesPage;
