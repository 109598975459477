import React from 'react';
import PageTitle from '../PageTitle';
import Post from '../Post';
import EnvironmentalInjusticePage from './EnvironmentalInjusticePage';
import HomeOccupationNoisePage from './HomeOccupationNoisePage';

const HomePage = () => (
    <div className="page-component">
    <PageTitle text="Welcome!" />
    <div className="page__div">
        Use the menu bar above to navigate all our posts or check out our latest posts below.
    </div>
    <div className="post__border">
      <EnvironmentalInjusticePage />
    </div>
    <Post
      type="injustice"
      month="Aug"
      year="2020"
      linkText="Supervisors Adopt New General Plan Noise Chapter Allowing Noise Increase Facilitating Events On Residential Parties"
      route="/GPNoiseChapter"
    />
    <Post
        type="injustice"
        month="Aug"
        year="2020"
        linkText="Supervisors Adopt Noise Ordinance Allowing Over 7-Fold Noise Increase Facilitating Vacation Rental Party Houses"
        route="/noiseOrdinance"
    />
    <Post
        type="fire"
        month="Aug"
        year="2020"
        linkText="Grand Jury Puts County On Hot Seat Over Fire Protection And Fire Risk Mitigation"
        route="/fireProtection"
    />
    <Post
        type="fire"
        month="Aug"
        year="2020"
        linkText="Supervisors Adopt Substandard Fire Code"
        route="/FireCode"
    />
    <Post
      type="event"
      month="Apr"
      year="2020"
      linkText="Stealth change made to new Noise Planning Chapter exempted commercial events on residential parcels from noise level limits"
      route="/stealthChangeNC"
    />
    <Post
      type="plane"
      month="Mar"
      year="2020"
      linkText="FAA shifted and concentrated the SJC northwest arrivals towards the Summit Area
       and away from the cities of Monte Sereno, Los Gatos, Almaden, and San Jose "
      route="/SJCBrixxArrivals"
    />
    <Post
      type="event"
      month="Feb"
      year="2020"
      linkText="False statement made by Planning Department to public and Board Of Supervisors facilitates approval of General Plan amendments allowing more noise on residential parcels"
      route="/falseStatement"
    />
    <Post
      type="event"
      month="Feb"
      year="2020"
      linkText="Stealth change made to the General Plan Amendments allows County to grant permits that override all noise level limits"
      route="/stealthChangeGP"
    />
    <Post
      type="gj"
      month="Feb"
      year="2020"
      linkText="Request to the Santa Cruz County Grand Jury to investigate the Santa Cruz County Board of Supervisors over event noise irregularities"
      route="/grandJury"
    />
    <Post
      type="plane"
      month="Feb"
      year="2020"
      linkText="Perverted use of EPA Noise Normalization factors by County attempts to double acceptable air traffic noise over rural residents"
      route="/GenPlanNoise1"
    />
    <Post
      type="plane"
      month="Feb"
      year="2020"
      linkText="Distortions in FAA NextGen Environmental Assessment maps obfuscate route locations and route changes"
      route="/mapDistortions"
    />
  </div>
);

export default HomePage;
