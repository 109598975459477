import React from 'react';
import PageTitle from '../PageTitle';

const AboutPage = () => (
  <div className="page-component">
    <PageTitle text="About blueskies4scco.com" />
    <div className="page__div">
      blueskies4scco.com is a website/blog that currently is focusing on issues 
      impacting residents in the unincorporated areas of Santa Cruz County.
    </div>
    <div className="page__div">
      blueskies4scco.com is a website/blog that was created by and
      is maintained by a Santa Cruz County resident.
    </div>
    <div className="page__div">
      Current plans are to implement features empowering residents to
      organize and create ballot propositions to maintain and improve residents' quality of life
      and to restore integrity to our County Government.
    </div>
    <div className="page__div">This website is currently under construction.</div>
  </div>
);

export default AboutPage;
