import React from 'react';
import {
  faBalanceScaleRight,
  faCheck,
  faChevronDown,
  faChevronUp,
  faArrowLeft,
  faAsterisk,
  faGavel,
  faHome,
  faPlane,
  faBars,
  faTimes,
  faBullhorn,
  faFire,
  faFireAlt
} from '@fortawesome/free-solid-svg-icons';
import { library } from '@fortawesome/fontawesome-svg-core';
import './App.css';
import { host } from './globals';
import AppRouter from './routers/AppRouter';

library.add(faBalanceScaleRight, faCheck, faChevronDown, faChevronUp, faArrowLeft, faAsterisk, faBullhorn, faGavel, faHome, faBars, faPlane, faTimes, faFire, faFireAlt);

const App = () => <AppRouter />;

export default App;
