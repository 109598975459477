import React from 'react';
import { NavLink } from 'react-router-dom';
import Figure from '../Figure';
import PageTitle from '../PageTitle';
import Post from '../Post';
import WhatCanYouDo from '../WhatCanYouDo';

const HomeOccupationNoisePage = () => (
    <div className="page-component">
        <PageTitle text="Supervisors Allow Significant Increase In Noise From Home Occupations" />
            <div className="page__div">
                Currently, no noise from stationary noise sources (not transportation) from 
                home occupations is allowed to cross property boundaries.
            </div>
            <div className="page__div">
                The Supervisors will adopt on Tuesday an Ordinance Amending the Home Occupation 
                Chapter of the Santa Cruz County Code allowing a substantial increase in noise from
                home occupations with <span className="bolder">no California Environmental Quality Act (CEQA)
                review and no Environmental Impact Report (EIR)
                </span>.
            </div>
            <div>
                <Figure
                    src="./images/HomeOccupationNoise.PNG"
                    alt="From County published document showing the changes to the Home Occupation noise standards"
                    attribution="From the changes to the Home Occupation Chapter"
                    figcaption="From County published document showing the changes to the Home Occupation noise standards"
                />
            </div>
            <div className="page__div">
                <ul className="gj-ul">
                    The changes specify three noise standards for a Home Occupation.
                    <li className="gj-li">the Offensive Noise Ordinance</li>
                    <li className="gj-li">the new Noise Planning Chapter</li>
                    <li className="gj-li">the new General Plan Noise Element</li>
                </ul>
            </div>
            <div className="page__div">
                <p>The new Offensive Noise Chapter allows 75 decibels to cross property boundaries.</p>
                <p>The new General Plan Noise Element allows granting of permits overriding of all noise limits.</p>
                <p>
                    The new Noise Planning Chapter states that new noise generating land uses shall not exceeed the
                    standards in Table 9-3, see Table 9-3 below.  However, the County attempted to exempt all Commercial 
                    Events from the noise limits by making a stealth change to the Noise Planning Chapter. 
                </p>
                <NavLink to="/stealthChangeGP">See the stealth change the County made to the new Noise Planning Chapter here</NavLink>
            </div>
            <Figure 
                src="./images/Table9-3.PNG"
                alt="Table 9-3 from the General Plan Noise Element"
                attribution="From the new General Plan Noise Element"
                figcaption="Table 9-3 Maximum Allowable Noise Exposure Stationary Noise Sources"
            />
            <div className="page__div">
                <h3>Links:</h3>
                <a className="page__anchor" href="https://www.codepublishing.com/CA/SantaCruzCounty/#!/SantaCruzCounty13/SantaCruzCounty1315.html">
                    new Santa Cruz County Noise Planning Chapter, scroll to 13.15.070 Noise generating land use
                </a>
                <a className="page__anchor" href="https://www.codepublishing.com/CA/SantaCruzCounty/#!/SantaCruzCounty13/SantaCruzCounty1315.html#13.15.050">
                    Santa Cruz County Offensive Noise Chapter
                </a>
                <a className="page__anchor" href="https://www.sccoplanning.com/Portals/2/County/userfiles/106/GP_Chapter%209_Noise.pdf">
                    Santa Cruz County General Plan Noise Element
                </a>
            </div>           
        <WhatCanYouDo />
    </div>
);

export default HomeOccupationNoisePage;