import React from 'react';
import MenuBar from './MenuBar';
import MenuDrawer from './MenuDrawer';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      drawerIsOpen: false,
    };
  }

  drawerHandler = () => {
    this.setState((prevState) => ({ drawerIsOpen: !prevState.drawerIsOpen }));
  };

  closeDrawerHandler = () => {
    this.setState(() => ({ drawerIsOpen: false }));
  }

  render() {
    const { drawerIsOpen } = this.state;
    return (
      <header className="header">
        <h1>
          <span className="header__h1 large-font">Blue Skies For</span>
          <span className="header__h1 large-font">Santa Cruz County</span>
          <span className="header__h1 medium-font header__h1--border">
            A Blog For Rural Residents</span>
        </h1>
        <MenuBar
          drawerHandler={this.drawerHandler}
          closeDrawerHandler={this.closeDrawerHandler}
        />
        <div className="header__div">
          <MenuDrawer
            drawerHandler={this.drawerHandler}
            closeDrawerHandler={this.closeDrawerHandler}
            drawerIsOpen={drawerIsOpen}
          />
        </div>
      </header>
    );
  }
}

export default Header;
