import React from 'react';
import PageTitle from '../PageTitle';


const SJCPacArrivalsPage = () => (
  <div className="page-component">
    <PageTitle text="SJC Pacific Arrivals" />
    <div className="page__div">
      The FAA omitted these arrivals from the NextGen Environmental Assessment West flow proposed action diagram.
    </div>
    <div className="page__div">
      The FAA omitted these arrivals from the NextGen Environmental Assessment West flow no action diagram.
    </div>
    <div className="page__div">
      Jet noise was shifted from Woodside, Monte Sereno, the city of Los Gatos, and Almaden to the
      Summit area without due process.
    </div>
    <div className="page__div">
      These flights are about 3000 feet above ground level over the Summit area.
    </div>
    <a href="http://www.metroplexenvironmental.com/docs/norcal_metroplex/NorCal_OAPM_FEA_Complete.pdf">NorCal FAA Environmental Assessment Final</a>
    <a href="http://www.metroplexenvironmental.com/docs/norcal_metroplex/NorCal_OAPM_DEA_Complete.pdf">NorCal FAA Environmental Assessment Draft</a>
    <a href="http://www.metroplexenvironmental.com/norcal_metroplex/norcal_docs.html">All docs for NorCal NextGen</a>
  </div>
);

export default SJCPacArrivalsPage;
