import React from 'react';

const ImageContainer = (props) => {
  const { src, alt } = props;
  return (
    <div className="image-container">
      <img className="image-container__img" src={src} alt={alt} />
    </div>
  );
};

export default ImageContainer;
