import React from 'react';
import PageTitle from '../PageTitle';


const ToBeDonePage = () => (
  <div>
    <PageTitle text="To Be Done Soon ..." />
  </div>

);

export default ToBeDonePage;
