import React from 'react';
import FireCodePage from './FireCodePage';
import PageTitle from '../PageTitle';
import Post from '../Post';


const FirePage = () => (
    <div className="page-component">
        <PageTitle text="Fire Posts" />
        <Post
            type="fire"
            month="Aug"
            year="2020"
            linkText="Supervisors Adopt Substandard Fire Code"
            route="/FireCode"
        />
        <Post
            type="fire"
            month="Aug"
            year="2020"
            linkText="Grand Jury Puts County On Hot Seat Over Fire Protection And Fire Risk Mitigation"
            route="/FireProtection"
    />
    </div>
)

export default FirePage;