import { NavLink } from 'react-router-dom';
import React from 'react';
import PageTitle from '../PageTitle';

const DisclaimerPage = () => (
  <div className="page-component">
    <PageTitle text="Disclaimer" />
    <ul>
      <li>
        <h4>About</h4>
        <p>
          This website is a blog that explores
          issues impacting rural residents in Santa Cruz County.
          I am a Santa Cruz County resident.
        </p>
      </li>
      <li>
        <h4>Opinionated Content</h4>
        <p>
          The content of this website/blog represents my opinions and observations.
        </p>
      </li>
      <li>
        <h4>Terms of Use: Ommissions, Errors, Mistakes, and Licenses</h4>
        <p>
          The content of this website/blog is accurate to the best of my knowledge,
          but there may be ommissions, errors, and mistakes.
        </p>
        <p>Additional terms of use are specified in the <NavLink to="/licenses">Licenses</NavLink></p>
      </li>
      <li>
        <h4>Hold Harmless clause</h4>
        <p>
          The content of this website/blog is for informational/entertainment purposes only.
          The content of this website/blog should not be
          interpreted as professional advice for any purpose as I am not a professional;
          I am not an attorney, legal professional, sound expert, sound professional, doctor,
          medical professional, or health professional.
        </p>
      </li>
      <li>
        <h4>Reservation Of Rights</h4>
        <p>
        I reserve the right to change how I run this website at any time.  I reserve the right to change the
        content of this website at any time.  I reserve the right to change the focus of this website at any time.
        I reserve the right to change how I manage this website at any time.
        </p>
      </li>
    </ul>
  </div>
);

export default DisclaimerPage;
